import React from "react"

import Img from "gatsby-image"

import styled from "styled-components"

const ImgContainer = styled.div`
  display: grid;
  grid-gap: 2vmin;
  grid-template-columns: repeat(6, min(15vmin, 200px));
  grid-template-columns: repeat(6, min(calc(var(--vmin, 1vmin) * 15), 200px));

  /* overflow: hidden; */
  grid-auto-flow: dense;

  // Extra small devices (portrait phones, less than 576px)
  & > * {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    & > * {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2),
        0 3px 20px 0 rgba(0, 0, 0, 0.19);
    }
  }

  & > * {
    transition: all ease-in-out 0.5s;
  }
  & > :hover {
    /* filter: blur(4px); */

    transform: scale(1.01);

    cursor: pointer;
  }

  .gallery-grid-row-1 {
    grid-row: span 1;
  }
  .gallery-grid-row-2 {
    grid-row: span 2;
  }
  .gallery-grid-row-3 {
    grid-row: span 3;
  }
  .gallery-grid-row-4 {
    grid-row: span 4;
  }
  .gallery-grid-row-5 {
    grid-row: span 5;
  }
  .gallery-grid-row-6 {
    grid-row: span 6;
  }

  .gallery-grid-col-1 {
    grid-column: span 1;
  }
  .gallery-grid-col-2 {
    grid-column: span 2;
  }
  .gallery-grid-col-3 {
    grid-column: span 3;
  }
  .gallery-grid-col-4 {
    grid-column: span 4;
  }
  .gallery-grid-col-5 {
    grid-column: span 5;
  }
  .gallery-grid-col-6 {
    grid-column: span 6;
  }
`

const NormalImage = styled.div`
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const getClassNameFromXbyX = (gridWidth, gridHeight) => {
  if (
    gridWidth !== null &&
    gridHeight !== null &&
    gridWidth > 0 &&
    gridWidth <= 6 &&
    gridHeight > 0 &&
    gridHeight <= 6
  ) {
    return `gallery-grid-row-${gridHeight} gallery-grid-col-${gridWidth}`
  } else {
    return "gallery-grid-row-1 gallery-grid-col-1"
  }
}

const Gallery = ({ images }) => {
  return (
    <ImgContainer>
      {images.map(({ id, fluid, fixed, src, gridWidth, gridHeight }) => {
        if (fixed) {
          return (
            <Img
              key={id}
              fixed={fixed}
              className={getClassNameFromXbyX(gridWidth, gridHeight)}
            />
          )
        } else if (fluid) {
          return (
            <Img
              key={id}
              fluid={fluid}
              className={getClassNameFromXbyX(gridWidth, gridHeight)}
            />
          )
        } else if (src) {
          return (
            <NormalImage
              key={id}
              className={getClassNameFromXbyX(gridWidth, gridHeight)}
            >
              <img src={src} alt={""} />
            </NormalImage>
          )
        } else {
          return <div>Could not locate picture !</div>
        }
      })}
    </ImgContainer>
  )
}

export default Gallery
